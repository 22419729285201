import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Button} from "react-bootstrap";
import {Link} from "react-router-dom";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ToyotaImage from '../assets/images/vehicles/toyota/toyota.png';
import hachbackImage from '../assets/images/vehicles/hachback/i20.png';
import sedanImage from '../assets/images/vehicles/sedan.png';
import innovaImage from '../assets/images/vehicles/innova2.jpg';
import crystaImage from '../assets/images/vehicles/crysta.png';

const CarOffers = () => {

    const [isLoading, setIsLoading] = useState(false);

    const [cars, setCars] = useState(null);
    const [brands, setBrands] = useState(null);
    const [models, setModels] = useState(null);


    useEffect(() => {


    }, []);

    return (
        <div id="car-offers" style={{clear: "both"}}>
            <Container className="py-4">
              <Row className="mb-5">
                <Col>
                    <h1 className="fs-1 text-center text-uppercase">Our Cars</h1>
                </Col>
              </Row>
                <Row>
                    <Col xs={6} md={4} className="py-2" key={`offer1`}>
                        <div className="gallery-box p-2">
                            <div className="gallery-img">
                                <LazyLoadImage src={ToyotaImage} className="img-fluid" effect="blur"/>
                            </div>
                            <div className="gallery-content text-center">
                                <h3 className="fs-4 fw-600 p-0">
                                    Toyota
                                </h3>
                                <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color">
                                    18/km
                                </p>
                                <div className="d-grid pb-2">
                                    <Link>
                                        <Button variant="primary rent-now-button primary-bg-color border-0 rounded-1 px-4 fw-bold">Book Now</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={4} className="py-2" key={`offer1`}>
                        <div className="gallery-box p-2">
                            <div className="gallery-img">
                                <LazyLoadImage src={hachbackImage} className="img-fluid" effect="blur"/>
                            </div>
                            <div className="gallery-content text-center">
                                <h3 className="fs-4 fw-600 p-0">
                                    HachBack
                                </h3>
                                <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color">
                                    11/km
                                </p>
                                <div className="d-grid pb-2">
                                    <Link>
                                        <Button variant="primary rent-now-button primary-bg-color border-0 rounded-1 px-4 fw-bold">Book Now</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={4} className="py-2" key={`offer1`}>
                        <div className="gallery-box p-2">
                            <div className="gallery-img">
                                <LazyLoadImage src={sedanImage} className="img-fluid" effect="blur"/>
                            </div>
                            <div className="gallery-content text-center">
                                <h3 className="fs-4 fw-600 p-0">
                                    Sedan
                                </h3>
                                <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color">
                                    12/km
                                </p>
                                <div className="d-grid pb-2">
                                    <Link>
                                        <Button variant="primary rent-now-button primary-bg-color border-0 rounded-1 px-4 fw-bold">Book Now</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={4} className="py-2" key={`offer1`}>
                        <div className="gallery-box p-2">
                            <div className="gallery-img">
                                <LazyLoadImage src={innovaImage} className="img-fluid" effect="blur"/>
                            </div>
                            <div className="gallery-content text-center">
                                <h3 className="fs-4 fw-600 p-0">
                                    Innova
                                </h3>
                                <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color">
                                    18/km
                                </p>
                                <div className="d-grid pb-2">
                                    <Link>
                                        <Button variant="primary rent-now-button primary-bg-color border-0 rounded-1 px-4 fw-bold">Book Now</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={4} className="py-2" key={`offer1`}>
                        <div className="gallery-box p-2">
                            <div className="gallery-img">
                                <LazyLoadImage src={crystaImage} className="img-fluid" effect="blur"/>
                            </div>
                            <div className="gallery-content text-center">
                                <h3 className="fs-4 fw-600 p-0">
                                    Innova Crysta
                                </h3>
                                <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color">
                                    20/km
                                </p>
                                <div className="d-grid pb-2">
                                    <Link>
                                        <Button variant="primary rent-now-button primary-bg-color border-0 rounded-1 px-4 fw-bold">Book Now</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default CarOffers;