import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {IoLocation} from "react-icons/io5";
import {BsTelephoneFill} from "react-icons/bs";
import {GrMail} from "react-icons/gr";
import {BiLogoFacebook, BiLogoLinkedin} from "react-icons/bi";
import {AiFillInstagram, AiOutlineTwitter} from "react-icons/ai";
import {useLocation} from "react-router-dom";


const Footer = () => {

  const location = useLocation();

  return <>
    {
        !location.pathname.includes("admin") &&
        <footer id="footer" className="secondary-bg-color">
          <Container className="pt-3 pb-2">
            <Row>
              <Col>
                <h1 className="fs-1 text-center quinary-color">Om Namoh Cab</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <h4 className="fs-4 text-white fw-700">Regular Trip</h4>
                    
                    <div className="form-group">
                      <ul className="list-inline campany-list" style={{color:"white"}}>
                        <li ><a href="#" className='text-white'>Mumbai To Ashtvinayak</a></li>
                        <li><a href="#" className='text-white'>Mumbai To Aurangabad</a></li>
                        <li><a href="#" className='text-white'>Mumbai To Audha</a></li>
                        <li><a href="#" className='text-white'>Mumbai To Trambkeshwar</a></li>
                        <li><a href="#" className='text-white'>Mumbai To Ajintha</a></li>
                        <li><a href="#" className='text-white'>Mumbai To Vai</a></li>
                        <li><a href="#" className='text-white'>Mumbai To Tuljapur</a></li>
                        <li><a href="#" className='text-white'>Mumbai To kokan</a></li>
                        <li><a href="#" className='text-white'>Mumbai To navbaragpur</a></li>
                      </ul>
                    </div>
                  </Col>
                  <Col>
                    <h4 className="fs-4 text-white fw-700">Trips</h4>
                    <ul className="list-inline campany-list">
                      <li><a href="#" className='text-white'>Mumbai To Pune</a></li>
                      <li><a href="#" className='text-white'>Mumbai To Nashik</a></li>
                      <li><a href="#" className='text-white'>Mumbai To Aurangabad</a></li>
                      <li><a href="#" className='text-white'>Mumbai To Satara</a></li>
                      <li><a href="#" className='text-white'>Mumbai To Solapur</a></li>
                      <li><a href="#" className='text-white'>Mumbai To Kolhapur</a></li>
                      <li><a href="#" className='text-white'>Mumbai To Mahableswar</a></li>
                      <li><a href="#" className='text-white'>Mumbai To Alibug</a></li>
                      <li><a href="#" className='text-white'>Mumbai To Shirdi</a></li>
                    </ul>
                    
                  </Col>
                  <Col>
                    <h4 className="fs-4 text-white fw-700">Trips</h4>
                    <ul className="list-inline campany-list">
                      <li><a href="#" className='text-white'>Mumbai To Goa</a></li>
                      <li><a href="#" className='text-white'>Mumbai To Bhandardara</a></li>
                      <li><a href="#" className='text-white'>Pune To Goa</a></li>
                      <li><a href="#" className='text-white'>Mumbai To Malvan</a></li>
                      <li><a href="#" className='text-white'>Pune To Malvan</a></li>
                    </ul>
                  </Col>
                  <Col>
                    <h4 className="fs-4 text-white fw-700">Contact Us</h4>
                    <p className="fs-6 text-white m-0">
                  <span>
                    <IoLocation className="header-line-2-icon"/>&nbsp;
                    <a href="#" target="_blank" className="text-white">Akshar CHS C Wing room no 401 , near Pratab tokij khopat Thane West- 400601</a>
                  </span>
                      <br/>
                      <span>
                    <BsTelephoneFill size="0.9em" className="header-line-2-icon"/>&nbsp;
                        <a href="tel:+919321097027" target="_blank" className="text-white">+91 9321097027</a>
                  </span>
                      <br/>
                      <span>
                    <GrMail className="header-line-2-icon"/>&nbsp;
                        <a href="mailto:omnamohcab@gmail.com" target="_blank" className="text-white">omnamohcab@gmail.com</a>
                  </span>
                    </p>
                    <div className="social-icon">
                      <ul>
                        <li><a href="https://www.facebook.com/" target="_blank"><BiLogoFacebook/></a></li>
                        <li><a href="https://twitter.com/" target="_blank"><AiOutlineTwitter/></a></li>
                        <li><a href="https://www.linkedin.com/" target="_blank"><BiLogoLinkedin/></a></li>
                        <li><a href="https://www.instagram.com/" target="_blank"><AiFillInstagram/></a></li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="text-white text-center fs-6 mt-2 mb-1">
                &COPY; Copyright {new Date().getFullYear()} <a href="#" className='text-white'>Om Namoh Cab</a> | Created by <a href="http://www.ellipsesofttech.com" target="_blank" className='primary-color'>Ellipse Software Technology</a><br/><br/>   &nbsp;
                  
                </p>
              </Col>
            </Row>
          </Container>
        </footer>
    }
    </>
};
export default Footer;