import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css';
import './App.scss';

import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./redux/app/store";

import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"

import ScrollToTop from "./config/ScrollToTop";

import Header from "./components/header";
import Footer from './components/footer';

import Home from './pages/home';


import About from './pages/about/about';
import Client from './pages/client/client';
import Services from './pages/services/services';
import Vehicles from './pages/vehicles/vehicles';


function App() {

    const persistor = persistStore(store);

  return (
      <Provider store={store}>
          <PersistGate persistor={persistor}>
              <Router>
                <ScrollToTop />
                <Header />
                <Routes>
                  <Route path="/" element={<Home />}/>
                  <Route path="/about" element={<About />} />
                  <Route path="/client" element={<Client />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/vehicles" element={<Vehicles />} />
                </Routes>
                <Footer />
              </Router>
          </PersistGate>
      </Provider>
  );
}

export default App;
