import React from 'react';
import ServicesSection from '../../components/services-section';
const Services = () => {

    return (
        <div id="services">
            <ServicesSection />
        </div>
    );
};
export default Services;