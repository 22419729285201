import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Button} from "react-bootstrap";

import {loadingContent} from "./general/general-components";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import localtrip from '../assets/images/services/localcity.png';
import airportTrip from '../assets/images/services/airport.png';
import onewayTrip from '../assets/images/services/onewaytrip.png';
import outstation from '../assets/images/services/outstation.png';
const ourServices = () => {
    useEffect(() => {
    }, []);

    return (
        <div id="car-offers" style={{clear: "both"}}>
            <Container className="py-4">
              <Row className="mb-5">
                <Col>
                    <h1 className="fs-1 text-center text-uppercase">Our Services</h1>
                </Col>
              </Row>
                <Row>
                    <Col xs={6} md={6} className="py-2" key={`offer1`}>
                        <div className="gallery-box p-2">
                          <div className="gallery-img">
                                <LazyLoadImage src={localtrip} className="img-fluid" style={{width: "30%",
                                display: "block", marginLeft: "auto", marginRight: "auto"}} effect="blur"/>
                            </div>
                            <div className="gallery-content text-center">
                                <h3 className="fs-4 fw-600 p-0">
                                  Local Car
                                </h3>
                                <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                                These are short-term rentals for use within a city or local area. They are ideal for running errands, attending meetings, or exploring a city.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={6} className="py-2" key={`offer1`}>
                        <div className="gallery-box p-2">
                            <div className="gallery-img">
                                <LazyLoadImage src={outstation} className="img-fluid" style={{width: "30%",
                                display: "block", marginLeft: "auto", marginRight: "auto"}} effect="blur"/>
                            </div>
                            <div className="gallery-content text-center">
                                <h3 className="fs-4 fw-600 p-0">
                                  Outstation
                                </h3>
                                <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                                Outstation rentals are for longer trips outside of the city or to another city. They are suitable for weekend getaways, family vacations, or business trips.
                                </p>
                                
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={6} className="py-2" key={`offer1`}>
                        <div className="gallery-box p-2">
                            <div className="gallery-img">
                                <LazyLoadImage src={airportTrip} className="img-fluid" style={{width: "30%",
                                display: "block", marginLeft: "auto", marginRight: "auto"}} effect="blur"/>
                            </div>
                            <div className="gallery-content text-center">
                                <h3 className="fs-4 fw-600 p-0">
                                  Airport
                                </h3>
                                <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                                  Airport are often convenient for travelers who need a vehicle upon arriving at an airport. Many car rental companies have counters at airports for easy pick-up and drop-off.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={6} className="py-2" key={`offer1`}>
                        <div className="gallery-box p-2">
                            <div className="gallery-img">
                                <LazyLoadImage src={onewayTrip} className="img-fluid" style={{width: "30%",
                                display: "block", marginLeft: "auto", marginRight: "auto"}} effect="blur"/>
                            </div>
                            <div className="gallery-content text-center">
                                <h3 className="fs-4 fw-600 p-0">
                                  One-Way
                                </h3>
                                <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                                One-way rentals allow customers to pick up a vehicle at one location and return it to another. This is useful for those traveling between cities or airports.
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default ourServices;