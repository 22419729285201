import React from 'react';

import {Container, Row, Col} from "react-bootstrap";

import {AiOutlineSafety} from "react-icons/ai";
import {HiOutlineStatusOnline} from "react-icons/hi";
import {BiSolidOffer} from "react-icons/bi";


const FeaturesSection = () => {
    return (
    <div id="features-section">
        <Container className="py-5">
            <Row>
                <Col>
                  <h1 className="quinary-color fs-1 p-0 mb-4">WHY CHOOSE US</h1>
                </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <AiOutlineSafety size="2.5em" color="white"/>
                <h4 className="quinary-color fs-5 py-1">Vehicle Safety</h4>
                <p className="quinary-color fs-6 m-0 pb-2">Regular maintenance checks are performed to ensure that vehicles are safe and in good condition for passengers</p>
              </Col>
              <Col xs={4}>
                <HiOutlineStatusOnline size="2.5em" color="white"/>
                <h4 className="quinary-color fs-5 py-1">GPS Tracking</h4>
                <p className="quinary-color fs-6 m-0 pb-2">we use GPS technology to track the location of vehicles in real-time, providing added security for passengers </p>
              </Col>
              <Col xs={4}>
                <BiSolidOffer size="2.5em" color="white"/>
                <h4 className="quinary-color fs-5 py-1">Emergency Assistance</h4>
                <p className="quinary-color fs-6 m-0 pb-2">We have protocols in place to handle emergencies, including providing access to emergency services and support.</p>
              </Col>
            </Row>
        </Container>
    </div> 
    );
};
export default FeaturesSection;