import React from 'react';

import {Container, Row, Col} from "react-bootstrap";


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import punecity from '../assets/images/trip/punecity.png';
import shirdi from '../assets/images/trip/shirdi.jpg';
import kolhapur from '../assets/images/trip/kolhapur2.jpg';
import ashthvinayak from '../assets/images/trip/ashthvinayak.jpg';

const DailyTrip = () => {
    return (
    <div id="features-section">
        <Container className="py-5">
            <Row>
                <Col>
                  <h1 className="quinary-color fs-1 p-0 mb-4">Famous Destinations</h1>
                </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="gallery-box p-2">
                  <div className="gallery-img">
                    <LazyLoadImage src={ashthvinayak} className="img-fluid" style={{width: "100%",
                                display: "block", marginLeft: "auto", marginRight: "auto"}} effect="blur"/>
                  </div>
                  <div className="gallery-content text-center">
                    <h3 className="fs-4 fw-600 p-0" style={{color:'white'}}>
                      Mumbai To Ashthvinayak Darshan
                    </h3>
                    <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
										  
                    Ashtavinayak refers to the eight revered temples of Lord Ganesha in the Indian state of Maharashtra. The Ashtavinayak Yatra is a pilgrimage that covers these eight temples, which are located in different parts of the state. Here is some general information about the Ashtavinayak temples:
                      <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                        <b>Moreshwar Temple (Morgaon):</b> 
                        This temple is located in Morgaon, Pune district. It is believed to be the starting point of the Ashtavinayak Yatra. The temple is dedicated to Lord Ganesha, also known as Mayureshwar.
                      </p>
                      <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                        <b>Siddhivinayak Temple (Siddhatek):</b> 
                        The Siddhivinayak Temple is situated in Siddhatek, Ahmednagar district. It is believed to be the second temple to visit during the Ashtavinayak Yatra.
                      </p>
                      <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                        <b>Ballaleshwar Temple (Pali):</b> 
                        Located in Pali, Raigad district, this temple is dedicated to Lord Ganesha and is the only Ashtavinayak temple named after a devotee (Ballala).
                      </p>
                      <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                        <b>Varadavinayak Temple (Mahad):</b> 
                        The Varadavinayak Temple is situated in Mahad, Raigad district. It is one of the most visited temples in the Ashtavinayak Yatra.
                      </p>
                      <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                        <b>Chintamani Temple (Theur):</b> 
                        This temple is located in Theur, Pune district. It is believed that visiting this temple can help in fulfilling one's desires and removing worries (chinta).
                      </p>
                      <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                        <b>Girijatmaj Temple (Lenyadri):</b> 
                        The Girijatmaj Temple is situated in Lenyadri, Pune district. It is located on a hill and is one of the unique temples in the Ashtavinayak Yatra.
                      </p>
                      <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                        <b>Vighnahar Temple (Ozar):</b> 
                        Located in Ozar, Pune district, this temple is dedicated to Lord Ganesha. It is believed that visiting this temple can remove obstacles (vighna) from one's life.
                      </p>
                      <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                        <b>Mahaganapati Temple (Ranjangaon):</b> 
                        The Mahaganapati Temple is situated in Ranjangaon, Pune district. It is the eighth and final temple in the Ashtavinayak Yatra.
                      </p>

									  </p>
                  </div>
                </div>
              </Col>
              <Col xs={6}>
                <div className="gallery-box p-2">
                  <div className="gallery-img">
                    <LazyLoadImage src={punecity} className="img-fluid" style={{width: "100%",
                                display: "block", marginLeft: "auto", marginRight: "auto"}} effect="blur"/>
                  </div>
                  <div className="gallery-content text-center">
                    <h3 className="fs-4 fw-600 p-0" style={{color:'white'}}>
                                  Mumbai To Pune
                    </h3>
                    <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                      Pune, a city in the Indian state of Maharashtra, is known for its rich history, vibrant culture, and a mix of modern and traditional attractions. Here are some popular places to visit in Pune:

                      <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}><b>Shaniwar Wada:</b> This historic fortification and palace is an iconic landmark in Pune, known for its intricate architecture and historical significance.</p>

                      <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}><b>Dagdusheth Halwai Ganpati Temple:</b> This famous Ganesh temple is a center of devotion and a popular tourist destination.</p>
                      <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}><b>Sinhagad Fort:</b> Located on a hill, this fort offers stunning views of the surrounding countryside. It's a popular spot for trekking and picnics.</p>
                      <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}><b>Saras Baug:</b> A well-maintained garden with a Ganesh temple and a beautiful lake.
                      Darshan Museum: This museum provides insights into the life and teachings of Sadhu Vaswani, a spiritual leader.</p>
                    </p>   
                  </div>
                </div>
              </Col>
              <Col xs={6}>
                <div className="gallery-box p-2">
                            <div className="gallery-img">
                                <LazyLoadImage src={shirdi} className="img-fluid" style={{width: "100%",
                                display: "block", marginLeft: "auto", marginRight: "auto"}} effect="blur"/>
                            </div>
                            <div className="gallery-content text-center">
                                <h3 className="fs-4 fw-600 p-0" style={{color:'white'}}>
                                  Mumbai To Shirdi
                                </h3>
                                <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                                  Shirdi is a small town located in the Ahmednagar district of the Indian state of Maharashtra. It is famous for being the home of the revered Indian spiritual leader, Sai Baba, who lived there for much of his life. Sai Baba is a prominent figure in Indian spirituality and is venerated by people of various religious backgrounds.
                                  <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}><b>Sai Baba Temple:</b> This is the main temple in Shirdi, dedicated to Sai Baba. It is one of the most visited and revered places in the town.
                                  </p>
                                  <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}><b>Chavadi:</b> Chavadi is a building where Sai Baba used to spend alternate nights. It has become a place of religious importance.</p>
                                  <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}><b>Samadhi Mandir:</b> This is the place where the mortal remains of Sai Baba are interred. Devotees come here to pay their respects.</p>
                                  
                                </p>
                      </div>
                  </div>
              </Col>
              <Col xs={6}>
                <div className="gallery-box p-2">
                            <div className="gallery-img">
                                <LazyLoadImage src={kolhapur} className="img-fluid" style={{width: "100%",
                                display: "block", marginLeft: "auto", marginRight: "auto"}} effect="blur"/>
                            </div>
                            <div className="gallery-content text-center">
                                <h3 className="fs-4 fw-600 p-0" style={{color:'white'}}>
                                  Mumbai To Kolhapur
                                </h3>
                                <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}>
                                Kolhapur is a city located in the southwestern part of the Indian state of Maharashtra. It is the district headquarters of the Kolhapur district and is known for its historical, cultural, and economic significance. Here are some key aspects of Kolhapur:
                                  <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}><b>Mahalaxmi Temple:</b> The Mahalaxmi Temple is one of the most famous religious sites in Kolhapur. It is dedicated to the goddess Mahalaxmi and is known for its architectural beauty and spiritual significance.</p>

                                  <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}><b>Shri Chhatrapati Shahu Museum:</b> This historical palace, built in the 19th century, serves as a museum showcasing artifacts and memorabilia from the Chhatrapati rulers of Kolhapur. The palace architecture and the surrounding gardens are also worth exploring.</p>
                                  <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}><b>Rankala Lake:</b> Rankala Lake is a picturesque and popular spot in Kolhapur. It's an ideal place for leisurely walks, boating, and enjoying the scenic beauty. The lake has a serene ambiance and is particularly lovely during sunset.</p>
                                  <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color" style={{textAlign:"justify"}}><b>Panhala Fort:</b> Situated about 20 kilometers from Kolhapur, Panhala Fort is one of the largest and most important forts in Maharashtra. It offers a glimpse into the historical significance of the region and provides panoramic views of the landscape.</p>
                                </p>
                      </div>
                  </div>
              </Col>
            </Row>
        </Container>
    </div> 
    );
};
export default DailyTrip;