import React, {useEffect} from 'react';

//import useAuthentication from "../hooks/useAuthentication";

import {useNavigate, Link, NavLink, useLocation} from "react-router-dom";
import {Container, Row, Nav, Navbar, NavDropdown, Col, Button} from "react-bootstrap";

import Swal from "sweetalert2";

import {IoLocation, IoLocationOutline} from "react-icons/io5";
import { AiOutlineWhatsApp } from "react-icons/ai";
import {BsTelephoneFill} from "react-icons/bs";
import {GrMail} from "react-icons/gr";
import {useSelector} from "react-redux";

const Header = () => {

    const location = useLocation();

    const user = useSelector(({UserSlice}) => UserSlice.user);
    //const {signOutCall} = useAuthentication();

    const handleLogout = async () => {
        //await signOutCall();
    }

    const handleHelpButtonClick = e => {
        e.preventDefault()

        Swal.fire(
            'Do you need help?',
            'You can contact with us 24/7.',
            'question'
        )
    }

    return <>
        {
            !location.pathname.includes("admin") &&
            <header id="header">
                
                <Navbar bg="dark" data-bs-theme="dark" collapseOnSelect expand="lg" className="bg-body-tertiary header-line-1">
                    <Container>
                        <Navbar.Brand as={Link} to="/">Om Namoh Cab</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link as={Link} to="/" eventKey="i">Home</Nav.Link>
                                <Nav.Link as={Link} to="/about" eventKey="i">About</Nav.Link>
                                <Nav.Link as={Link} to="/services" eventKey="i">Services</Nav.Link>
                                <Nav.Link as={Link} to="/vehicles" eventKey="i">Vehicles</Nav.Link>
                                <Nav.Link as={Link} to="/client" eventKey="i">Client</Nav.Link>
                                <Nav.Link as={Link} to="/contact" eventKey="i">Contact</Nav.Link>
                            </Nav>
                            
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Container fluid className="header-line-2 py-md-2 text-white fs-5">
                    <Row className="justify-content-md-center text-center">
                        <Col xs={12} md={10}>
                            <Row>
                            
                                <Col xs={12} md={3}>
                                    <span className="fs-6">                                        
                                        <a href="https://api.whatsapp.com/send?phone=9321097027" className="text-white"> <AiOutlineWhatsApp className="header-line-2-icon"/>&nbsp; What's App</a>
                                    </span>
                                </Col>
                                <Col xs={12} md={3}>
                                    <span className="fs-6">
                                        <IoLocation className="header-line-2-icon"/>&nbsp;
                                        <a href="#" target="_blank" className="text-white">Thane</a>
                                    </span>
                                </Col>
                                <Col xs={12} md={3}>
                                    <span className="fs-6">
                                        <BsTelephoneFill size="0.9em" className="header-line-2-icon"/>&nbsp;
                                        <a href="tel:+919321097027" target="_blank" className="text-white">+91 9321097027</a>
                                    </span>
                                </Col>
                                <Col xs={12} md={3}>
                                    <span className="fs-6">
                                        <GrMail className="header-line-2-icon"/>&nbsp;
                                        <a href="mailto:omnamohcab@gmail.com" target="_blank" className="text-white">omnamohcab@gmail.com</a>
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </header>
        }
    </>
};

export default Header;