import React, {useEffect, useState} from 'react';

import Form from 'react-bootstrap/Form';
import {Container, Row, Col, Button} from "react-bootstrap";


const CarSearch = () => {

    
    const [formData, setFormData] = useState({})
    const [isLoading, setIsLoading] = useState(false);


    const handleModelChange = e => {
    }
    const handleCarChange = e => {
        let value = e.target.value ? parseInt(e.target.value) || 0 : "";        
    }

    useEffect(() => {
    }, []);
    const handleFormChange = e => {

        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleCaptchaChange = value => {
        
    }
    const handleContactSubmit = event => {

        event.preventDefault();

        setIsLoading(true);


    }
    return (
        <div id="car-search" className="pb-1">
                <Container className="py-5">
                <Row>
                    <Col>
                        <h1 className="quinary-color fs-2 p-0 mb-2">
                            Book Car Now
                        </h1>
                        <p className="quinary-color fs-5 p-0 m-0 mb-5">
                            Share user Details for car booking
                        </p>
                        <Container>
                            <Row>
                            <Form onSubmit={handleContactSubmit}>
                                <Col className="my-2 row"> 
                                    <div className="col-md-6 col-12">
                                        <Form.Control  type="tel" name="phone" placeholder="Mobile Number" className="mb-2" onChange={handleFormChange} required={true}/>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Form.Control  type="tel" name="name" placeholder="user Name" className="mb-2" onChange={handleFormChange} required={true}/>
                                    </div>                                            
                                </Col>
                                <Col className="my-2 row"> 
                                    <div className="col-md-6 col-12">
                                        <Form.Control  type="tel" name="pickup" placeholder="Pick Up" className="mb-2" onChange={handleFormChange} required={true}/>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Form.Control  type="tel" name="Ddestination" placeholder="Destination" className="mb-2" onChange={handleFormChange} required={true}/>
                                    </div>                                            
                                </Col>
                                <Col xs={12} md={3} className="my-2">    
                                    <div className="d-grid">
                                        <Button variant="primary" size="lg" className="search-btn w-100">Book Now</Button>
                                    </div>    
                                </Col>
                                </Form>
                                
                            </Row>
                        </Container>
                    </Col>
                </Row>
                </Container>
        </div>
    );
};
export default CarSearch;